import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "helpers/asyncActionHandler";
import {
  productCreate,
  productDelete,
  productEdit,
  productList,
  productPricingGroup,
  productRemoveList,
  productRemoveSingleView,
  productSingleView,
  productSku,
  productFilterByPromotion,
  pendingSalesPurchaseOrder,
  unitIdCheck
} from "redux/service/menuService/productService";

const initialState = {
  productListRes: { data: [], isFetching: false, error: null },
  productSingleViewRes: { data: [], isFetching: false, error: null },
  productSkuRes: { data: [], isFetching: false, error: null },
  productCreateRes: { data: [], isFetching: false, error: null },
  productEditRes: { data: [], isFetching: false, error: null },
  productDeleteRes: { data: [], isFetching: false, error: null },
  productRemoveListRes: { data: [], isFetching: false, error: null },
  productRemoveSingleViewRes: { data: [], isFetching: false, error: null },
  productRemoveUpdateRes: { data: [], isFetching: false, error: null },
  productFilterByPromotionRes: { data: [], isFetching: false, error: null },
  productPricingGroupRes: { data: [], isFetching: false, error: null },
  pendingSalesPurchaseOrderRes: { data: [], isFetching: false, error: null },
  unitIdCheckRes: { data: [], isFetching: false, error: null },
};

export const productSlice = createSlice({
  name: "productSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: productList, name: "productListRes" },
      { api: productSingleView, name: "productSingleViewRes" },
      { api: productSku, name: "productSkuRes" },
      { api: productCreate, name: "productCreateRes" },
      { api: productEdit, name: "productEditRes" },
      { api: productDelete, name: "productDeleteRes" },
      { api: productRemoveList, name: "productRemoveListRes" },
      { api: productRemoveSingleView, name: "productRemoveSingleViewRes" },
      { api: productFilterByPromotion, name: "productFilterByPromotionRes" },
      { api: productPricingGroup, name: "productPricingGroupRes" },
      { api: pendingSalesPurchaseOrder, name: "pendingSalesPurchaseOrderRes" },
      { api: unitIdCheck, name: "unitIdCheckRes" }
    ];
    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { productDispatch } = productSlice.actions;
