import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";
import axios from "axios";

// essential categories List
export const essentialCategoriesList = createAsyncThunk(
  "essential-categories-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/categories?children`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// essential All List

export const essentialList = createAsyncThunk(
  "essential-list",
  async (bodyParams, thunkAPI) => {
    var bodyParamData =
      bodyParams?.label === "searching" ? bodyParams?.data : bodyParams;
    var bodyParamsearch =
      bodyParams?.label === "searching" ? bodyParams?.search : "";

    try {
      const response = await API.get(
        `/commonlist?required=${bodyParamData}&search=${bodyParamsearch}`
      );
      // const response = await API.get(`/commonlist?required=${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// essential common list add
export const essentialListAdd = createAsyncThunk(
  "essential-list-add",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/commonAdd `, bodyParams);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Multiple Image Delete
export const essentialMultipleImageDelete = createAsyncThunk(
  "essential-image-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/productimage/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Multiple Document Delete
export const essentialMultipleDocumentDelete = createAsyncThunk(
  "essential-document-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/productdocument/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Add Tax
export const essentialAddTax = createAsyncThunk(
  "essential-add-tax",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/tax`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Add Product Search List
export const essentialProductList = createAsyncThunk(
  "essential-product-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/productslists`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Add Product Category Search List
export const essentialCategoryProductList = createAsyncThunk(
  "essential-Category-product-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/products`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Catgory Shortcut
export const essentialCategoryShortcutList = createAsyncThunk(
  "essential-category-shortcutlist",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/listcategory?shortcut=`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Customer List with out pagination with search
export const essentialCustomerList = createAsyncThunk(
  "essential-customer-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customerslist`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const essentialPricingGroupCustomerList = createAsyncThunk(
  "essential-pricinggroup-customer-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/customersnotinpricinggroup/${bodyParams.id}`,
        { params: bodyParams }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Select Delete
export const commonEssentialListDelete = createAsyncThunk(
  "common-select-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/commondelete/${bodyParams.id}?type=${bodyParams.type}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Send Email
export const sendEmail = createAsyncThunk(
  "send-email",
  async ({ id, name, data }, thunkAPI) => {
    try {
      const response = await API.post(`/send_email/${id}/${name}`, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Purchase Order Download

export const downloadView = createAsyncThunk(
  "download-view",
  async ({ id, name, type, number = "", reEnterPage, formValues }, thunkAPI) => {
    try {
      const responsivePrint = ["successreceipt"].includes(name);
      const response = await API.get(`/pdf_download/${id}/${name}`, {
        responseType: !responsivePrint && "blob",
        params: formValues
      }).then((blob) => {
        if (responsivePrint) {
          // const a = window.open("", "");
          // a.document.write("<html><Style></Style>");
          // a.document.write("<body>");
          // a.document.write(blob.data);
          // a.document.write("</body></html>");
          // a.document.close();
          // a.onload = () => {
          //   a.print();
          // };
          // const printWindow = window.open("", "", "width=800,height=600");
          const iframe = document.createElement("iframe");
          iframe.style.width = "0";
          iframe.style.height = "0";
          iframe.srcdoc = blob.data;
          document.body.appendChild(iframe);
          iframe.onload = () => {
            iframe.contentWindow.print();
            // const checkIframeInterval = setInterval(() => {
            //   if (document.body.contains(iframe)) {
            //     clearInterval(checkIframeInterval);
            //     reEnterPage && window.location.reload()
            //   }
            // }, 1000);
          };
          // printWindow.document.body.appendChild(iframe);
        } else if (type === "print") {
          const url = URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          const printWindow = window.open(url);
          printWindow.onload = () => {
            printWindow.print();
          };
          // -----------------iframe code----------------------- //
          // const printWindow = window.open("", "", "width=800,height=600");
          // const iframe = document.createElement("iframe");
          // iframe.style.width = "100%";
          // iframe.style.height = "100%";
          // iframe.src = url;
          // iframe.onload = () => {
          //   iframe.contentWindow.print();
          // };
          // iframe.onload = () => {
          //   iframe.contentWindow.print();
          // };
          // document.body.appendChild(iframe);
        } else {
          const downloadname =
            name === "quotation" || name === "estimation"
              ? `${number}.pdf`
              : `${name}${number}.pdf`;
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = downloadname;
          a.click();
        }
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const singledownloadView = createAsyncThunk(
  "download",
  async ({ id, name, type, ext }, thunkAPI) => {
  try {
  const response = await API.get(`/getimageurl/${id}?type=${type}`);
  const fileUrl = response.data?.url;
  const fileExtension = fileUrl
  ? fileUrl.split(".").pop().toLowerCase()
  : "";
  
  let mimeType;
  switch (fileExtension) {
  case "pdf":
  mimeType = "application/pdf";
  break;
  case "jpg":
  case "jpeg":
  mimeType = "image/jpeg";
  break;
  case "png":
  mimeType = "image/png";
  break;
  case "gif":
  mimeType = "image/gif";
  break;
  case "xls":
  case "xlsx":
  case "xml":
  mimeType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  break;
  case "doc":
  case "docx":
  mimeType =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  break;
  case "txt":
  mimeType = "text/plain";
  break;
  default:
  mimeType = "application/octet-stream";
  }
  
  let blob;
  try {
  const fileResponse = await axios.get(fileUrl, {
  responseType: "blob",
  });
  blob = fileResponse?.data;
  } catch (error) {
  console.log(error);
  }
  
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name + "." + fileExtension;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
  } catch (error) {
  return thunkAPI.rejectWithValue(error);
  }
  }
  );
  

// export const downloadView = createAsyncThunk(
//   "download-view",
//   async ({ id, name, type }, thunkAPI) => {
//     try {
//       const response = await API.get(`/pdf_download/${id}/${name}`, {
//         responseType: "arraybuffer",
//       });

//       const pdfDoc = await PDFDocument.load(response);

//       if (type === "print") {
//         const printWindow = window.open("", "", "width=800,height=600");
//         const pdfBytes = await pdfDoc.save();
//         const blob = new Blob([pdfBytes], { type: "application/pdf" });
//         const url = URL.createObjectURL(blob);
//         const iframe = document.createElement("iframe");
//         iframe.style.width = "100%";
//         iframe.style.height = "100%";
//         iframe.src = url;
//         iframe.onload = () => {
//           iframe.contentWindow.print();
//         };
//         printWindow.document.body.appendChild(iframe);
//       } else {
//         const pdfBytes = await pdfDoc.save();
//         const blob = new Blob([pdfBytes], { type: "application/pdf" });
//         const url = URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.href = url;
//         a.download = `${name}.pdf`;
//         a.click();
//         URL.revokeObjectURL(url);
//       }

//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// price Group list taken by customer
export const priceGroupSingleCustomer = createAsyncThunk(
  "price-customer-single",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customerpricinggroups/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Convert csv to jason
export const getCsvToJson = createAsyncThunk(
  "get-csv-to-json",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.postForm(`/getJsonFromCsv`, bodyParams);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
