import { Input, Switch } from "@mantine/core";
import { Controller } from "react-hook-form";

const ToggleField = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  disabled,
  display,
  error,
  type,
  maxLength,
  defaultChecked,
  readOnly,
  labelPosition,
  leftLabel,
  onChange,
}) => {
  return (
    <>
      <Input.Label miw={"max-content"} size="xs" required={required}>
        {label}
      </Input.Label>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Switch
            display={display}
            size="xs"
            labelPosition={labelPosition ? labelPosition : "left"}
            label={leftLabel}
            readOnly={readOnly}
            {...field}
            required={required}
            error={error && error.message}
            icon={icon}
            disabled={disabled}
            checked={field.value === "Yes"}
            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
            defaultChecked={field.value}
          />
        )}
      />
    </>
  );
};

export default ToggleField;
