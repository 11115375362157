import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// product List
export const productList = createAsyncThunk(
  'product-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/products`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// product Single view
export const productSingleView = createAsyncThunk(
  'product-single-view',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/product/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// product Sku
export const productSku = createAsyncThunk(
  'product-sku',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`productsku`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// product Delete
export const productDelete = createAsyncThunk(
  'product-delete',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`product/${bodyParams}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Product Create
export const productCreate = createAsyncThunk(
  'product-create',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`product`, bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Product Edit
export const productEdit = createAsyncThunk(
  'product-edit',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`product/${bodyParams.id}`, bodyParams.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// product Remove vList
export const productRemoveList = createAsyncThunk(
  'product-remove-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/removedproducts`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// product Remove singleview
export const productRemoveSingleView = createAsyncThunk(
  'product-remove-single-view',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/removedproduct/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
//product list filter by promotion
export const productFilterByPromotion = createAsyncThunk(
  'product-filter-promotion',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/productlist/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// // product Delete Update
export const singleSupplierProductDelete = createAsyncThunk(
  'product-price-group',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/productsupplier/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// product Price Group
export const productPricingGroup = createAsyncThunk(
  'product-price-group',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/productpricinggroup/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

export const pendingSalesPurchaseOrder = createAsyncThunk(
  'pending-sales-order',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/checkpendingsalespurchaseorders/${bodyParams?.supplier_id}/${bodyParams?.product_id}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// unit Id check
export const unitIdCheck = createAsyncThunk(
  'unit-id-check',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/checkpendingsalespurchaseorders/${bodyParams.supplier_id}/${bodyParams.product_id}/${bodyParams.unit_type}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })