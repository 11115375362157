import { Image } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { convertDate, formateDate } from "helpers/Golbal";
import { datepickerIcon } from "helpers/images";
import { Controller } from "react-hook-form";

const DatepickerDisplay = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  mt,
  clearable,
  variant,
  miw,
  display,
  disabled,
  readOnly,
  minDate,
  maxDate,
  onChange,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            display={display}
            size="xs"
            disabled={disabled}
            valueFormat="YYYY-MM-DD"
            clearable={clearable}
            maw={400}
            readOnly={readOnly}
            mt={mt}
            label={label}
            variant={variant ?? "filled"}
            placeholder={placeholder}
            error={error && error.message}
            // allowSingleDateInRange={allowSingleDateInRange}
            // rightSection={<Image src={datepickerIcon} width={15} />}
            icon={<Image src={datepickerIcon} width={"1rem"} />}
            withAsterisk={required}
            onChange={(data) => {
              data ? field.onChange(convertDate(data)) : field.onChange(null);
              onChange && onChange(convertDate(data));
            }}
            defaultValue={field.value ? formateDate(field.value) : null}
            value={field.value ? formateDate(field.value) : null}
            // {...field}
            miw={miw}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      />
    </>
  );
};

export default DatepickerDisplay;
